import React, {Suspense, lazy} from 'react';
import {Routes, Route} from 'react-router-dom';
import Loading from '../components/LoadingCircle';

const PageHome = lazy(() => import('../pages/PageHome'));
const PageAccessibility = lazy(() => import('../pages/PageAccessibility'));
const PageArchiveBlog = lazy(() => import('../pages/PageArchiveBlog'));
const PageArchiveEvents = lazy(() => import('../pages/PageArchiveEvents'));
const PageArchivePress = lazy(() => import('../pages/PageArchivePress'));
const PageArchiveCasting = lazy(() => import('../pages/PageArchiveCasting'));
const PageArchiveBusinesses = lazy(() => import('../pages/PageArchiveBusinesses'));
const PageSingleBlog = lazy(() => import('../pages/PageSingleBlog'));
const PageSingleEvent = lazy(() => import('../pages/PageSingleEvent'));
const PageSinglePress = lazy(() => import('../pages/PageSinglePress'));
const PageSingleCasting = lazy(() => import('../pages/PageSingleCasting'));
const PageWYSIWYG = lazy(() => import('../pages/PageWYSIWYG'));
const PageCareers = lazy(() => import('../pages/PageCareers'));
const PageSupportOrgs = lazy(() => import('../pages/PageSupportOrgs'));
const PageOUTflix = lazy(() => import('../pages/PageOUTflix'));
const PageZA = lazy(() => import('../pages/PageZA'));
const PageLanding = lazy(() => import('../pages/PageLanding'));
const PageNotFound = lazy(() => import('../pages/PageNotFound'));
const PageLegal = lazy(() => import('../pages/PageLegal'));


function AppRouter() {

   const baseURL = 'https://outtvglobal.com';
   // const baseURL = 'https://dev.outtv.ca';

  return (
   <Suspense fallback={<Loading/>}>
      <Routes>
         <Route path='/' exact element={<PageHome baseURL={baseURL}/>} />
         <Route path='blog' element={<PageArchiveBlog baseURL={baseURL} cpt='blog' slug='blog' taxonomy='topic'/>} />
         <Route path='events' element={<PageArchiveEvents baseURL={baseURL} cpt='events' slug='events' taxonomy='location'/>} />
         <Route path='press-releases' element={<PageArchivePress baseURL={baseURL} cpt='press-releases' slug='press-releases' taxonomy='year' />} />
         <Route path='casting' element={<PageArchiveCasting baseURL={baseURL} cpt='casting' slug='casting'/>} />
         <Route path='perks' element={<PageArchiveBusinesses baseURL={baseURL} cpt='business' slug='perks' />} />
         <Route path='blog/:slug' element={<PageSingleBlog baseURL={baseURL} />} />
         <Route path='events/:slug' element={<PageSingleEvent baseURL={baseURL} />} />
         <Route path='press-releases/:slug' element={<PageSinglePress baseURL={baseURL} />} />
         <Route path='casting/:slug' element={<PageSingleCasting baseURL={baseURL} />} />
         <Route path='contact-us' element={<PageWYSIWYG baseURL={baseURL} slug='contact-us'/>} />
         <Route path='programming-submission' element={<PageWYSIWYG baseURL={baseURL} slug='programming-submission'/>} />
         <Route path='advertising' element={<PageWYSIWYG baseURL={baseURL} slug='advertising'/>} />
         <Route path='careers' element={<PageCareers baseURL={baseURL} />} />
         <Route path='accessibility' element={<PageAccessibility baseURL={baseURL} slug='accessibility'/>} />
         <Route path='accessibility-plan' element={<PageLegal baseURL={baseURL} slug='accessibility-plan'/>} />
         <Route path='terms-conditions' element={<PageLegal baseURL={baseURL} slug='terms-conditions' />} />
         <Route path='privacy-policy' element={<PageLegal baseURL={baseURL} slug='privacy-policy'/>} />
         <Route path='support-orgs' element={<PageSupportOrgs baseURL={baseURL} />} />
         <Route path='outtv-proud' element={<PageWYSIWYG baseURL={baseURL} slug='outtv-proud'/>} />
         <Route path='outflix' element={<PageOUTflix baseURL={baseURL} slug='outflix'/>} />
         <Route path='za' element={<PageZA baseURL={baseURL} slug='za'/>} />
         {/* <Route path='outspoken' element={<PageWYSIWYG baseURL={baseURL} slug='outspoken'/>} /> */}
         <Route path=':slug' element={<PageLanding baseURL={baseURL} />} />
         <Route path='page-not-found' element={<PageNotFound baseURL={baseURL} />} />
         <Route path='*' element={<PageNotFound baseURL={baseURL} />} />
      </Routes>
   </Suspense>
  );
};

export default AppRouter;
